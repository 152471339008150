<template>
  <div class="page-wrap">
    <div v-if="createzujian==true">
      <!-- <createtemplate   :title.sync="createzujian" ></createtemplate> -->
      <createtemplate @close-view="returnList" ></createtemplate>
    </div>
    <div v-else-if="editComponent==true">
      <!-- <editcomponent :editId="editid" :title.sync="editComponent" ></editcomponent> -->
      <editcomponent :editId="editid" @close-view="returnList1"  ></editcomponent>
    </div>
    <div v-else-if="showTable">
      <div class="l-box">
        <div class="input-group">
          <div class="flex f-wrap">
            <div class="wrap flex">
              <!-- <div class="label">合同</div> -->
              <div class="r-find">
                <Input v-model="formValidate.xinxi" placeholder="请输入模板名称进行搜索" style="width: 300px" />
              </div>
            </div>
          </div>
          <div class="flex f-wrap" >
            <div class="r-btn flex">
              <div class="s-btn flex-c" @click="getListSearch">搜索</div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="choose-first">
        <div class="top-nav">
          <div class="nav-title">模板管理</div>
          <div class="input-group flex">
            <div class="wrap flex">
              <div class="label">模板名称：</div>
              <div class="r-find">
                <Input v-model="formValidate.template_name" placeholder="请输入模板名称" style="width: 280px" />
              </div>
            </div>
          </div>
          <div class="middle-nav">
            <div class="upload-style-i" v-if="formValidate.contract_imgs != ''">
              <div class="up-del middle-finish">
                <img src="../../assets/imgs/123.png" height="58" width="46"/>
                <div class="finish-success">上传成功</div>
                <div class="change-status flex-c">
                  <div class="see flex-c" @click="previewIt">预览</div>
                  <div class="see flex-c" @click="delPic">删除</div>
                </div>
              </div>
            </div>
            <div class="upload-style-i" v-if="formValidate.contract_imgs == ''">
              <Upload
                  :show-upload-list="false"
                  :headers='headers'
                  :action="imageAction"
                  class="up-del flex-c"
                  :on-format-error="handleFormatError"
                  :format="['jpg','jpeg','png','doc','docx','pdf']"
                  :on-success="upload_success">
                <div class="up-img">
                  <img src="../../assets/imgs/12.png"/>
                </div>
                <div class="up-btn flex-c">选择文件</div>
              </Upload>
            </div>
          </div>
          <div class="table-btn blue" @click="addBtn">添加模板</div>
        </div>
      </div> -->
      <div class="table-card">
        <div class="table-wrap">
          <div class="table-btn blue" @click="creatTem">创建</div>
        </div>

        <Table :columns="columnTable" :loading="loading" :data="dataTable">
          <template slot-scope="{ row }" slot="documentType">
            <p>{{ row.document_type === 1 ?'Word文档':'' }}</p>
            <p>{{ row.document_type === 3 ?'Pdf':'' }}</p>
            <p>{{ row.document_type !== 3 && row.document_type !== 1 ?'图片':'' }}</p>
          </template>
          <template slot-scope="{row}" slot="action">
            <div class="flex-c">
              <div class="table-btn blue" @click="kaiqi(row)">{{ row.status_ == 1 ? '启用' : '禁用' }}</div>
              <!-- <div class="table-btn del" @click="tableSee(row)">预览</div> -->
              <!-- <div class="table-btn edit" @click="changeName(row)">编辑</div> -->
              <div class="table-btn edit" @click="editTem(row)">编辑</div>
              <div class="table-btn danger" @click="delTemplete(row.id)">删除</div>
            </div>
          </template>
        </Table>
        <Page :total="total"
              :current="formValidate.page"
              style="text-align:right;margin-top: 20px"
              show-elevator
              show-total
              @on-change="pageChange"
              :page-size="formValidate.limit"/>
      </div>
      <modal v-if="showModal" :modalInfo="modalInfo" @giveVal="getVal" @closeModal="showModal=false"></modal>
    </div>
    <div v-else>
      <Preview ref="preview" @titleSync="seeBack"></Preview>
    </div>
  </div>

</template>

<script>
  import {getCookies} from '@/utils/helper'
  import config from '@/config/index'
  import modal from '@/components/modal/index'
  import Preview from '../../components/preview/index'
  import  createtemplate from './components/createtemplate.vue'
  import  editcomponent from './components/editcomponent.vue'
  import {
    edittempupdate,
    addtemplate,
    templist,
    typetemplate,
    deleteTable,
    edittemplate,
    delEnclosure
  } from '../../api/interFace'
  export default {
    name: "index",
    components:{
      modal,
      Preview,
      createtemplate,
      editcomponent
    },
    data(){
      return{
        createzujian:false,//创建组件
        editComponent:false,
        editid:0,
        formValidate:{
          xinxi: '',//测试查询假名称
          template_name: '',
          contract_imgs:[],
          template_content: '',
          status: "",
          page: 1,
          limit: 10,
          document_type:'',
          upload_type:2
        },
        showModal:false,
        showTable:true,
        modalInfo: {
          title: '修改模板名称',
          inputList: [
            {
              label: '模板名称',
              inputType: 'text',
              inputVal: ''
            },
          ]
        },
        total: 0,
        loading:false,
        columnTable: [
          {
            title: '序号',
            key: 'id',
            align: 'center',
            width: 80
          },
          {
            title: '模板名称',
            key: 'template_name',
            align: 'center'
          },
          {
            title: '模板编号',
            key: 'numbering',
            align: 'center'
          },
          {
            title: '模板类型',
            key: 'document_type',
            align: 'center',
            slot: 'documentType',
          },
          {
            title: '创建时间',
            key: 'created_at',
            align: 'center'
          },
          {
            title: '模板状态',
            key: 'status',
            align: 'center'
          },
          {
            title: '操作',
            slot: 'action',
            width: 320,
            align: 'center'
          }
        ],
        dataTable:[],
        useId:'',
        imageAction:config.apiUrl + '/adminapi/common/uploads',
        headers:{'Authori-zation' : getCookies('token')},
      }
    },
    created() {
      this.getlist()
    },
    methods:{
      // 文件类型上传失败
      handleFormatError() {
        this.$Message.warning('暂不支持上传此类型文件')
      },
      seeBack(){
        this.showTable = true
      },
      previewIt(){
        this.showTable = false
        this.$nextTick(()=>{
          this.$refs.preview.openIt(this.formValidate)
        })
      },
      tableSee(e){
        this.showTable = false
        this.$nextTick(()=>{
          this.$refs.preview.openIt(e)
        })
      },
      pageChange(index) {
        this.formValidate.page = index
        this.getlist()
      },
      delPic() {
        delEnclosure({img_url:this.accessories_url}).then(res=>{
          this.$Message.success(res.msg);
          this.formValidate.contract_imgs = ''
          this.formValidate.document_type = ''
        })
      },
      // 判断类型上传文件
      upload_success(e) {
        const msg = this.$Message.loading({
          content: '文件上传中，请等待...',
          duration: 0
        });
        this.formValidate.template_content = e.data.src
        //获取合同 HTML
        edittempupdate({path: e.data.src, type:1}).then(async res => {
          this.formValidate.contract_pdf = res.data.path
          this.formValidate.document_type= res.data.document_type
          this.formValidate.contract_imgs = res.data.imgs
          setTimeout(msg, 100);
        }).catch(res => {
          this.$Message.error(res.msg)
          setTimeout(msg, 100);
        })
      },
      // 开启状态
      kaiqi(row) {
        let status = null
        let content
        if (row.status_ == 0) {
          status = 1
          content = '请问您是否确认禁用此模板'
        } else {
          status = 0
          content = '请问您是否确认启用此模板'
        }
        this.$Modal.confirm({
          title: '操作提示',
          content: content,
          onOk: () => {
            typetemplate({id: row.id, status: status}).then(async res => {
              this.getlist()
              this.$Message.success(res.msg)
            }).catch(res => {
              this.$Message.error(res.msg)
            })
          },
          onCancel: () => {
          }
        });
      },
      returnList(val) {
        this.createzujian = val
      },
      returnList1(val) {
        this.editComponent = val
      },
      delTemplete(id) {
        this.$Modal.confirm({
          title: '操作提示',
          content: '请问您是否确认删除此模板，删除后无法恢复！！！',
          onOk: () => {
            deleteTable({id:id}).then(res=>{
              this.$Message.success(res.msg)
              this.getlist()
            }).catch(res=>{
              this.$Message.error(res.msg)
            })
          },
          onCancel: () => {
          }
        });

      },
      addBtn() {
        if (this.formValidate.template_name == '') return this.$Message.warning('请填写任务名称')
        if (this.formValidate.template_content == '') return this.$Message.warning('请上传文件')
        addtemplate(this.formValidate).then(async res => {
          this.getlist()
          this.$Message.success(res.msg)
          this.formValidate.template_name = ''
          this.formValidate.template_content = ''
          this.formValidate.contract_imgs = []
        }).catch(res => {
          this.$Message.error(res.msg)
        })
      },
      getListSearch(){
        //this.storePrams.page = 1
        templist({
          status: "",
          page: this.formValidate.page, // 当前页
          limit: this.formValidate.limit,
          title: this.formValidate.xinxi
        }).then(async res => {
          this.total = res.data.total
          this.dataTable = res.data.data || []
          this.loading = false
        }).catch(res => {
          this.loading = false
          this.$Message.error(res.msg)
        })
      },
      //创建模板
      creatTem(){
        this.createzujian = true
        // this.
      },
      editTem(e){
        this.editid = e.id
        this.editComponent =true
      },
      getlist() {
        this.loading = true
        templist({
          status: "",
          page: this.formValidate.page, // 当前页
          limit: this.formValidate.limit
        }).then(async res => {
          this.total = res.data.total
          this.dataTable = res.data.data || []
          this.loading = false
        }).catch(res => {
          this.loading = false
          this.$Message.error(res.msg)
        })
      },
      getVal(e) {
        edittemplate({
          template_name: e[0],
          id: this.useId
        }).then(async res => {
          this.$Message.success(res.msg)
          this.showModal = false
          this.getlist()
        }).catch(res => {
          this.$Message.error(res.msg)
        })
      },
      changeName(e){
        //this.showModal = true
        console.log("e",e)
        this.useId = e.id
        this.modalInfo.inputList[0].inputVal = e.template_name
      }
    }
  }
</script>

<style scoped lang="scss">
  .page-wrap {
    .top-change {
      width: 100%;
      background: #FFFFFF;
      border-radius: 8px;
      position: relative;
      height: 60px;
      margin-bottom: 20px;
      .l-where {
        position: absolute;
        left: 30px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #1B2742;
        span {
          color: #4877E8;
        }
      }
      .r-back {
        .iconfont {
          position: absolute;
          right: 30px;
          top: 50%;
          transform: translateY(-50%);
          font-size: 26px;
          cursor: pointer;

          &:hover {
            color: #4877E8;
          }
        }
      }
    }
    .table-card {
      width: 100%;
      padding: 24px;
      box-sizing: border-box;
      background: #FFFFFF;
      border-radius: 8px;
      position: relative;
      margin-top: 20px;
      .top-search {
        .search-btn {
          width: 78px;
          height: 30px;
          background: #4877E8;
          border-radius: 15px;
          font-size: 16px;
          font-weight: bold;
          color: #FFFFFF;
          margin-left: 20px;
          cursor: pointer;
          &:hover{
            opacity: .8;
          }
        }
      }
      .creat {
        width: 64px;
        height: 28px;
        background: #FFFFFF;
        border: 1px solid #4877E8;
        border-radius: 6px;
        font-size: 14px;
        font-weight: bold;
        color: #4877E8;
        margin-top: 20px;
        margin-bottom: 20px;
        cursor: pointer;
        &:hover{
          background: #4877E8;
          color: white;
        }
      }
      .note-style {
        height: 30px;
        line-height: 30px;
        background: #FFFFFF;
        border: 1px solid #C8CEDA;
        border-radius: 4px;
        width: fit-content;
        padding: 0 8px;
        display: inline-block;
        font-size: 16px;
        color: #545F78;
        cursor: pointer;
      }
    }
    .first-in {
      display: flex;
      align-items: center;
      justify-content: center;
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      .check-item {
        width: 280px;
        height: 340px;
        background: #FFFFFF;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-right: 20px;
        border: 2px solid #fff;
        box-sizing: border-box;
        &:hover {
          border: 2px solid #4877E8;
        }
        .iconfont {
          font-size: 80px;
          color: #6D92ED;
        }
        .word {
          margin-top: 12px;
          font-size: 28px;
          color: #545F78;
        }
      }
    }
    .choose-first {
      .top-nav {
        width: 100%;
        background: #FFFFFF;
        border-radius: 12px;
        padding: 21px 20px;
        box-sizing: border-box;
        .table-btn {
          margin-top: 20px;
          margin-left: 110px;
        }
        .input-group {
          margin-top: 20px;
          .wrap {
            margin-right: 20px;
          }
          .label {
            font-size: 14px;
            color: #545F78;
          }
        }
      }
      .have-sel {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
      }
      .middle-nav {
        margin-left: 70px;
      }
      .middle-r {
        width: 73%;
        background: #FFFFFF;
        border-radius: 12px;
        padding: 21px 20px;
        box-sizing: border-box;
        .upload-style-i {
          margin-top: unset;
        }
      }
      .third-nav {
        margin-top: 20px;
        width: 100%;
        background: #FFFFFF;
        border-radius: 12px;
        padding: 21px 20px;
        box-sizing: border-box;
        .table {
          width: 100%;
          border: 1px solid #E4EAF9;
          border-radius: 7px;
          margin-top: 20px;
          display: flex;
          .first-item {
            width: 18%;
          }
          .second-item {
            width: 24%;
          }
          .third-item {
            flex: 1;
          }
          .tab-label {
            height: 46px;
            background: #F6F7FC;
            font-size: 16px;
            font-weight: bold;
            color: #1B2742;
          }
          .tab-val {
            width: 100%;
            height:80px ;
          }
        }
      }
      .company {
        margin-top: 20px;
        width: 100%;
        background: #FFFFFF;
        border-radius: 12px;
        padding: 21px 20px;
        box-sizing: border-box;
        .more-del {
          width: 100%;
          background: #FFFFFF;
          border: 1px solid #E4EAF9;
          border-radius: 7px;
          margin-top: 12px;
          padding: 20px;
          box-sizing: border-box;
          .input-nav {
            .wrap {
              flex: 1;
            }
            .label {
              font-size: 14px;
              font-weight: bold;
              color: #1B2742;
            }
          }
          .check-nav {
            margin-top: 20px;
            display: flex;
            .label {
              font-size: 14px;
              font-weight: bold;
              color: #1B2742;
            }
            .last-btn {
              width: 100px;
              height: 30px;
              background: #FFFFFF;
              border: 1px solid #4877E8;
              border-radius: 6px;
              font-size: 11px;
              color: #4877E8;
              margin-left: 20px;
              cursor: pointer;
            }
          }
        }
      }
      .end-btn {
        margin-top: 20px;
        width: 100%;
        background: #FFFFFF;
        border-radius: 12px;
        padding: 21px 20px;
        box-sizing: border-box;
        .wrap {
          margin-left: 60px;
          .label {
            font-size: 14px;
            font-weight: bold;
            color: #1B2742;
          }
        }


      }
      .moreSign {
        margin-top: 20px;
        .select-it {
          width: 32%;
          height: 128px;
          background: #FFFFFF;
          border-radius: 12px;
          cursor: pointer;
          border: 2px solid #fff;
          box-sizing: border-box;
          &:hover {
            border: 2px solid #4877E8;
          }
          .icon-pic {
            font-size: 40px;
            color: #4877E8;
          }
          .pic-name {
            font-size: 21px;
            font-weight: bold;
            color: #1B2742;
            margin-left: 10px;
          }
        }
      }
    }
    .submit-btn {
      width: 100%;
      margin-top: 20px;
      height: 110px;
      cursor: pointer;
      background: #FFFFFF;
      border-radius: 12px;
      border: 2px solid #DBDEE6;
      box-sizing: border-box;
      &:hover {
        border: 2px solid #4877E8;
      }
      .iconfont {
        font-size: 50px;
        color: #4877E8;
      }
      span {
        font-size: 28px;
        font-weight: bold;
        color: #1B2742;
        margin-left: 12px;
      }
    }
    .submit-btn-else {
      border: unset;
    }
    .upload-style-i {
      margin-top: 20px;
      .up-del {
        width: 170px;
        height: 228px;
        background: #FFFFFF;
        border: 1px solid #C8CEDA;
        border-radius: 10px;
        .up-img {
          width: 105px;
          height: 110px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .up-btn {
        width: 90px;
        height: 30px;
        background: #4877E8;
        border-radius: 4px;
        font-size: 14px;
        color: #FFFFFF;
        cursor: pointer;
        margin-top: 12px;
      }
    }
    .else-up {
      display: flex;
      .upload-style {
        width: 100px;
        height: 100px;
        border-radius: 4px;
        border: 1px dashed #8a8f97;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;
      }
      .pic-wrap {
        display: flex;
        flex-wrap: wrap;
        margin-left: 12px;
      }
      .img-list {
        width: 100px;
        height: 100px;
        position: relative;
        margin-right: 8px;
        margin-bottom: 8px;
        border-radius: 4px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 4px;
        }
        .close {
          position: absolute;
          top: -15px;
          right: -15px;
          cursor: pointer;
          color: #dd0000;
          display: none;
        }
      }
      .img-list:hover .close {
        display: inline-block;
      }
    }

    .l-box {
      background: #FFFFFF;
      width: 100%;
      padding: 20px 20px;
      border-radius: 8px;
    }
    .input-group {
      display: flex;
    }
    .r-btn{
      margin-left: 30px;
      .s-btn {
        width: 78px;
        height: 30px;
        background: #4877E8;
        border-radius: 15px;
        font-size: 12px;
        font-weight: bold;
        color: #FFFFFF;
        cursor: pointer;
        margin-right: 20px;
        &:hover{
          opacity:.4;
        }
      }
      .d-btn {
        width: 78px;
        height: 30px;
        background: #F77F54;
        border-radius: 15px;
        font-size: 12px;
        font-weight: bold;
        color: #FFFFFF;
        cursor: pointer;
        &:hover{
          opacity:.4;
        }
      }
    }
    .table-wrap {
      width: 100%;
      box-sizing: border-box;
      border-radius: 8px;
      background: white;
      margin-bottom:10px ;
      .blue {
        margin-left: unset;
      }
    }

    .middle-finish {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      overflow: hidden;
      .finish-success {
        font-size: 16px;
        font-weight: bold;
        color: #4877E8;
        margin-top: 20px;
      }
      .change-status {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        .see {
          flex: 1;
          height: 30px;
          font-size: 14px;
          border: 1px solid #DBDEE6;
          cursor: pointer;
          &:hover {
            border-color: #1a9aef;
            color: #1a9aef;
          }
        }
      }
    }
  }

</style>
